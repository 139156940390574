import React, { useState } from 'react';
import ProductCard from '../../components/ProductCard';
import ProductModal from '../../components/ProductModal';
import construction from '../../assets/img/product/construction-materials1.jpeg';
import carpet from '../../assets/img/product/carpet.jpg';
import marble from '../../assets/img/product/marble.jpg';
import window from '../../assets/img/product/window-treatments.jpeg';
import mattress from '../../assets/img/product/Mattress.jpg';
import electronics from '../../assets/img/product/s.png';


const products = [
  {
    image: construction,
    name: 'Construction materials and supplies',
    description: '',
    price: '$49.99',
    category: 'Category 1',
    extraImages: [marble, window, electronics] // Extra images
  },
  {
    image: carpet,
    name: 'Carpet and flooring',
    description: '',
    price: '$59.99',
    category: 'Category 2',
    extraImages: [] // No extra images
  },
  {
    image: marble,
    name: 'Marble',
    description: '',
    price: '$69.99',
    category: 'Category 3',
    extraImages: [] // No extra images
  },
  {
    image: window,
    name: 'Window treatments',
    description: '',
    price: '$79.99',
    category: 'Category 4',
    extraImages: [] // No extra images
  },
  {
    image: mattress,
    name: 'Mattress',
    description: '',
    price: '$89.99',
    category: 'Category 5',
    extraImages: [] // No extra images
  },
  {
    image: electronics,
    name: 'Electronics',
    description: '',
    price: '$89.99',
    category: 'Category 6',
    extraImages: [] // No extra images
  }
];

function Products() {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const openModal = (product) => {
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  return (
    <section id="products">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl text-primary-light font-bold text-center mb-8">
          Our Products
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {products.map((product, index) => (
            <ProductCard
              key={index}
              image={product.image}
              name={product.name}
              description={product.description}
              price={product.price}
              onClick={() => openModal(product)}
            />
          ))}
        </div>
      </div>
      {selectedProduct && (
        <ProductModal
          isOpen={Boolean(selectedProduct)}
          onClose={closeModal}
          product={selectedProduct}
        />
      )}
    </section>
  );
}

export default Products;
