import React from 'react';
import { FiPhone } from 'react-icons/fi';
import logo from '../assets/img/logo.png';

const Navbar = () => {
  return (
    <nav className="bg-white border-gray-200 w-full">
      <div className="flex items-center justify-between p-5 w-full">
        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} className="h-6 md:h-16" alt="Logo" />
          <span className="self-center hidden md:block text-2xl font-semibold whitespace-nowrap text-black">International Trade</span>
          <span className="self-center md:hidden text-2xl font-semibold whitespace-nowrap text-white">RFI</span>
        </a>
        
        <div className="hidden md:flex items-center space-x-6 text-black">
          <p  className="text-black font-semibold text-xl cursor-pointer transition-colors duration-300 hover:text-primary-dark">Connecting The World</p>
        </div>

        <div className="flex items-center space-x-2 md:mr-20 text-white cursor-pointer transition-colors duration-300 hover:text-primary-dark">
          <FiPhone className="md:w-7 md:h-7 sm:w-4 sm:h-4 text-black" />
          <span className="sm:text-sm md:text-xl text-black">+1 (646) 229 9889 </span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
